const Content = {
    home: {
        AvatarURL: '/images/Avatar2.png',
        greetings: [
            'Hey There!',
            'Hello!',
            'What’s Up!?',
            'How is it going?',
            'How ya doing?',
            'Hi!',
            'Hola!',
        ],
        description: 'I’m Danny. Front End Developer.',
    },
    about: {
        funFacts: [
            'Soccer Player/Aficionado',
            'Love to Travel',
            'Love Italian Food',
            'Favorite Soccer Team - FC Barcelona',
            'Currently Listening to Freakonomics',
            'Currently Watching GOT',
        ],
        description: 'My name is Daniel Giraldo. I’m a Front End developer currently working at RevolutionCredit. I consider myself a creator and I’m consistently working on wide variety of projects in an effort to continue bringing my skills to the next level. At the moment I\'m super excited about the future of the web and how the community is finding ways to bridge the gap between the web and native apps. I\'m currently working with React/Redux and exploring the possibilities of PWAs at large scale applications.',
        techStack: {
            advanced: [
                'react',
                'js',
                'html',
                'css',
                'sass',
                'jquery',
                'wordpress'
            ],
            intermediate: [
                'node',
                'redux',
                'webpack',
                'babel',
                'adobe suite',
            ],
            some: [
                'ruby',
                'php',
                'python',
                'java',
            ],
        },

    },
    work: {
        projects: [
            {
                title: 'BeerMe',
                links: {
                    website: 'http://danielgiraldo.com/BeerMe/',
                    github: 'https://github.com/dgiraldo313/BeerMe',
                },
                description: 'Progressive Web App to search and find your new favorite beer from BreweryDB.The focus of this project is to work with the latest web technologies out there and set up a playground to allow me to continue adapting new tech to an evolving application.',
                techStack: [
                    'react',
                    'js',
                    'css',
                    'pwa',
                    'api',
                    'webpack',
                    'babel',
                ],
            },
            {
                title: 'Jacked Up',
                links: {
                    website: 'http://danielgiraldo.com/JackedUp/',
                    github: 'https://github.com/dgiraldo313/JackedUp',
                },
                description: 'Workout application that uses a deck of cards to randomly select exercises for the user to perform.',
                techStack: [
                    'react',
                    'html',
                    'sass',
                    'js',
                    'webpack'
                ],
            },
            {
                title: 'Fantasy Life App',
                links: {
                    website: 'http://fantasylifeapp.com/',
                    github: 'https://github.com/dgiraldo313/Fantasy_Life',
                },
                description: 'Designed and developed a mobile responsive landing page for Fantasy Life App. For this particular project I focused specifically on the use of transitions and the use of graphics to give a simple page life.',
                techStack: [
                    'html',
                    'jquery',
                    'sass',
                    'js',
                    'webpack'
                ],
            },
            {
                title: 'Portfolio',
                links: {
                    website: 'http://danielgiraldo.com/',
                    github: 'https://github.com/dgiraldo313/dgiraldo313.github.io',
                },
                description: 'Website you are currently on! After exploring many personal websites online I was inspired to revamp my existing personal website into a more interactive one to give the user a better experience. When coming up with the design, I came up with the idea to replicate the design of my favorite text editor (Atom) and so, I did! Website also dynamically changes greetings, as well as fun facts in the about page. \n\nUPDATE: Recently integrated a widget that allows me to update my activity in real time through text SMS. I used the Twilio API + a small Node/Express/MongoDB in order to store the data and display it on to the widget that you might have seen at the top of the screen.',
                techStack: [
                    'react',
                    'jquery',
                    'js',
                    'sass',
                    'html',
                    'webpack',
                    'node',
                    'api'
                ],
            },
            {
                title: 'NextMatch',
                links: {
                    github: 'https://github.com/dgiraldo313/nextMatch',
                },
                description: 'NextMatch is a web app that lets you find out when/who your favorite soccer team is playing next or show you live scores if the match is currently being played. It even lets you set up a reminder, so that you can get a text before the match starts.',
                techStack: [
                    'node',
                    'jquery',
                    'sass',
                    'html',
                    'api',
                    'databases',
                ],
            },
            {
                title: 'DrewLab',
                links: {
                    github: 'https://github.com/dgiraldo313/RAR_WebApp',
                },
                description: 'DrewLab is a custom web application built for the Economics Department at Drew University. The research platform a platform consists of a series of games rooted in measuring risk aversion ranging from a balloon that fills with cash to a Deal or No Deal style game. DrewLab will live on at Drew to encourage further collaboration between Drew’s Economics and Computer Science students to create new games and expand the platform for years to come.',
                techStack: [
                    'ror',
                    'html',
                    'js',
                    'sass',
                ],
            },
            {
                title: 'Web Mashup',
                links: {
                    github: 'https://github.com/dgiraldo313/web_mashup',
                },
                description: 'Web Mashup is an API that integrates into the Drew University Library search results page to provide users with links to PDFs from outside resources.',
                techStack: [
                    'ror',
                    'html',
                    'sass',
                    'api',
                ],
            },
        ],

        freelance: [
            {
                title: 'W4 Promotions',
                links: {
                    website: 'http://w4promotions.com/',
                    github: 0,
                },
                description: 'Designed and developed a brand new website to help them carry out their newest marketing campaign. After I was done with this project I moved on to developing a custom Portal where clients can upload large sized zip files, directly to W4 Promotions DropBox account.',
                techStack: [
                    'wordpress',
                    'php',
                    'jquery',
                    'sass',
                ],
            },
            {
                title: 'Cranford Soccer Club',
                links: {
                    website: 'https://cranfordsoccer.com/',
                    github: 0,
                },
                description: 'Worked closely with a soccer club to build a blog like website to help them keep parents informed. The goal was to move away from emailing and instead have all information available in one location. Worked on theme and plugin development to provide a better user experience.',
                techStack: [
                    'wordpress',
                    'php',
                    'jquery',
                    'sass',
                ],

            },
            {
                title: 'Positive Publications',
                links: {
                    website: 'http://www.positivepublications.us/',
                    github: 0,
                },
                description: 'First Website I ever built! Developed and deployed a static, mobile responsive website to help client display portfolio as well as provided services.',
                techStack: [
                    'html',
                    'css',
                    'js',
                    'jquery',
                ],

            },
        ],
    },
    contact: {

    },
};

export default Content;
